<template>
  <div id="app">
    <transition name="overflow-fade">
      <div
        v-if="$root.ready && overflow"
        class="ready-overflow"
        @click="openWebsite()"
      >
        <div class="text-center w-100 content">
          <div class="title">The Wedding</div>
          <span>of</span>
          <div class="mb-5 name">
            {{ wedding.man.surname }} 
            <span style="margin-top: -1rem; margin-bottom: -1rem;">&</span> 
            {{ wedding.woman.surname }}
          </div>

          <button
            class="text-uppercase btn btn-outline-primary"
            @click="openWebsite()"
          >
            Buka Undangan
          </button>
        </div>
      </div>
    </transition>

    <AGWAudio
      :file="music.file"
      :loop="music.loop"
      :autoplay="music.autoplay"
      ref="audio"
    />
    <AGWHero
      overlay
      :images="backgrounds"
      ref="hero"
      parallax
      target="#intro"
    />

    <div
      class="bg-white agw-content"
      :style="{ 'margin-top': `${$root.hero.height}px` }"
    >
      <AGWIntro
        id="intro"
        style="position:relative; box-shadow: 0 5px 15px rgb(175 150 105 / 34%)"
      />

      <AGWPawiwahan id="pawiwahan" class="bg-blue-light py-5" />
      <AGWMepandes id="mepandes" class="pad-y-60" :mepandes="mepandes" />

      <AGWCounter
        id="counter"
        class="pad-y-60"
        :background="background"
        :date="time.start"
      />
      <AGWGallery id="gallery" class="py-5" />
      <AGWMap
        :height="450"
        :url="map"
        :directionUrl="direction"
        :location="location"
        id="map"
      />
    </div>

    <div class="agw-content" style="background: transparent">
      <Himbauan />
      <attendance id="attendance" class="pad-y-60"></attendance>
    </div>

    <FooterCeremonyku ref="footer" />

    <transition name="slide-fade">
      <guest-box :floating="true" />
    </transition>

    <div class="snackbar" v-if="sw.updateExists">
      New version available!
      <button @click="refreshApp" class="btn">REFRESH</button>
    </div>
  </div>
</template>

<script>
const ImageHero1 =
  "https://ik.imagekit.io/aryraditya/ceremonyku/rae-nadia/IMG_0522.JPG?updatedAt=1638196962503&tr=w-700";
const ImageHero2 =
  "https://ik.imagekit.io/aryraditya/ceremonyku/rae-nadia/DSC09041.JPG?updatedAt=1638196961353&tr=w-700";
const ImageHero3 =
  "https://ik.imagekit.io/aryraditya/ceremonyku/rae-nadia/DSC09007.JPG?updatedAt=1638196960851&tr=w-700";

import Audio from "@/assets/audio/soundtrack.mp3";

import AGWHero from "@/components/Hero/HeroTwo";
import AGWIntro from "@/components/Intro";
import AGWPawiwahan from "@/components/Pawiwahan";
import AGWMepandes from "@/components/Mepandes";
import AGWCounter from "@/components/Counter";
import AGWGallery from "@/components/Gallery/GalleryOne";
import AGWMap from "@/components/Maps";
import AGWAudio from "@/components/Audio";
import GuestBox from "@/components/GuestBox";
import Attendance from "@/components/Attendance";
import Himbauan from "./components/Himbauan.vue";
import FooterCeremonyku from "@/components/Footer/Ceremonyku";

import { setInterval } from "timers";

const SESSION = [
  ['2021-12-22 14:00', 'Selesai'],
];

export default {
  name: "app",
  components: {
    AGWHero,
    AGWIntro,
    AGWPawiwahan,
    AGWMepandes,
    AGWCounter,
    AGWGallery,
    AGWMap,
    AGWAudio,
    GuestBox,
    Attendance,
    Himbauan,
    FooterCeremonyku,
  },
  data() {
    return {
      title: "Wedding",
      backgrounds: [ImageHero1, ImageHero2, ImageHero3],
      bgActive: 0,
      dividerHeight: 0,
      overflow: true,
      location: "Jl. Raya Uluwatu Pecatu, Rumah Keluarga Besar Tambyak Pecatu. Badung - Bali",
      map:
        "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3942.502451719058!2d115.12655586004257!3d-8.83272302427645!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd25ac47cd7c4f9%3A0x6884e25a3041305a!2sJl.%20Bambang%20Kembar%20No.4%2C%20Pecatu%2C%20Kec.%20Kuta%20Sel.%2C%20Kabupaten%20Badung%2C%20Bali%2080361!5e0!3m2!1sen!2sid!4v1638277291880!5m2!1sen!2sid",
      direction:
        "https://www.google.com/maps/dir//Jl.+Bambang+Kembar+No.4,+Pecatu,+Kec.+Kuta+Sel.,+Kabupaten+Badung,+Bali+80361/@-8.832723,115.1265559,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x2dd25ac47cd7c4f9:0x6884e25a3041305a!2m2!1d115.1264979!2d-8.8330193?hl=en",
      time: {
        start: this.$moment("2021-12-22 14:00"),
        end: "Selesai",
      },
      wedding: {
        man: {
          name: "I Wayan Rae Novendra Kusuma Yudha, S.E.",
          surname: "Rae",
          parents: "I Ketut Yudasuara, S.E, M.Par & Ni Nyoman Lindawati",
          description: "Putra pertama",
          address: "Br. Dinas Tambyak Pecatu, Badung - Bali",
          photo: "https://ik.imagekit.io/aryraditya/ceremonyku/rae-nadia/IMG_0556.JPG?updatedAt=1638196963644&tr=w-576,h-721,fo-custom,cm-extract"
        },
        woman: {
          name: "Ni Kadek Nadia Arvianti, A.Md",
          surname: "Nadia",
          parents: "I Nyoman Purwa & Ni Made Aris Suciati",
          address: "Kuwum Tegallinggah, Tabanan - Bali",
          description: "Putri kedua",
          photo: "https://ik.imagekit.io/aryraditya/ceremonyku/rae-nadia/IMG_0545.JPG?updatedAt=1638196962802&tr=w-581,h-728,fo-custom,cm-extract"
        },
      },
      mepandes: [
        // {
        //   name: 'I Gusti Ngurah Agung Teguh Aksama Putra',
        //   // image: 'https://ik.imagekit.io/ceremonyku/iyan-tini/SKP_4414e_XA6Xqi5UR_LiP.jpg?tr=w-671,h-671,fo-custom,cm-extract'
        // },
      ],
      gallery: [],
      music: {
        file: Audio,
        autoplay: true,
        loop: true,
      },
      sw: {
        refreshing: false,
        registration: null,
        updateExists: false,
      },
    };
  },
  computed: {
    background() {
      return this.backgrounds[this.bgActive];
    },
    session() {
      const url = new URLSearchParams(window.location.search);
      const sesi = Number(url.get("sesi")) || 1;
      return sesi > SESSION.length ? 1 : sesi;
    }
  },
  mounted() {
    const [start, end] = SESSION[this.session - 1] || SESSION[0];

    this.time.start = this.$moment(start);
    this.time.end = end;

    this.dividerHeight = `calc(100vh - ${this.$refs.footer.offsetHeight}px)`;
    setInterval(() => {
      let active = this.bgActive + 1;
      if (active >= this.backgrounds.length) {
        active = 0;
      }

      this.bgActive = active;
    }, 5000);
  },
  created() {
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.sw.refreshing) return;
        this.sw.refreshing = true;
        window.location.reload();
      });
    }
  },
  methods: {
    showRefreshUI(e) {
      this.sw.registration = e.detail;
      this.sw.updateExists = true;
    },
    refreshApp() {
      this.sw.updateExists = false;
      if (!this.sw.registration || !this.sw.registration.waiting) {
        return;
      }
      this.sw.registration.waiting.postMessage("skipWaiting");
    },
    openWebsite() {
      this.overflow = false;
      document.querySelector("#loading").classList.remove("active");
      if (this.$refs.audio) this.$refs.audio.play();
    },
  },
};
</script>

<style lang="scss" scoped>
.overflow-fade-enter-active {
  transition: all 1s ease;
}
.overflow-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.overflow-fade-enter,
.overflow-fade-leave-to {
  opacity: 0;
}
</style>
