import "intersection-observer";

import Vue from "vue";
import VueMoment from "vue-moment";
import AOS from "aos";
import axios from "axios";
import $ from "jquery";
import moment from "moment";

import App from "./App.vue";
// import VueAnalytics from 'vue-analytics';
import "./smoothscroll";

import "swiper/dist/css/swiper.css";
import "./scss/app.scss";
import "aos/dist/aos.css";
import "./registerServiceWorker";

const BASE_URL = "https://app.ceremonyku.com/api/public/1389";
// const BASE_URL = "http://127.0.0.1:13052";

window.$ = $;

Vue.config.productionTip = false;

moment.locale("id");
Vue.use(VueMoment, {
  moment
});

Vue.filter("two_digits", value => {
  if (value < 0) {
    return "00";
  }
  if (value.toString().length <= 1) {
    return `0${value}`;
  }
  return value;
});

new Vue({
  render: h => h(App),

  data: {
    ready: false,
    hero: {
      height: null
    },
    guest: {
      id: null,
      name: null,
      attendance: null,
      message: null,
      title: null,
      collapsed: false,
      floating: false
    },
    scroll: {
      Y: window.scrollY,
      X: window.scrollX
    }
  },

  mounted() {
    AOS.init({
      offset: 50
    });
    window.addEventListener("scroll", () => {
      this.guest.floating = window.scrollY > this.hero.height;
      (this.scroll.Y = window.scrollY), (this.scrollX = window.scrollX);
    });

    const path = document.querySelector(".progress-circle path");
    const pathLength = path.getTotalLength();

    // window.onload = () => {

    // };
    let time = 0;
    let interval = 2500;
    setInterval(() => {
      if (time <= interval) {
        let progress = pathLength - (time / (interval - 600)) * pathLength;
        path.style.strokeDashoffset = progress < 0 ? 0 : progress;
        time += 200;
      }
    }, 200);
    setTimeout(() => (this.ready = true), interval);

    this.getGuest().then(({ code, name, title, rsvp }) => {
      this.guest.name = name || null;
      this.guest.title = title || "Bpk/Ibu/Sdr/i";
      this.guest.id = code || null;
      this.guest.attendance = (rsvp ? rsvp.attendance : null) || null;
      this.guest.message = (rsvp ? rsvp.message : null) || null;
    });
  },
  methods: {
    guestToggle() {
      this.guest.collapsed = !this.guest.collapsed;
    },
    async getGuest() {
      let url = new URLSearchParams(window.location.search);
      const id = url.get("id");

      if (!id) {
        return {};
      }

      const { data } = await axios.get(`${BASE_URL}/guest/${url.get("id")}`);
      return data;
    },
    postAttendance(data = {}) {
      return axios.post(`${BASE_URL}/rsvp`, data);
    }
  },

  watch: {
    ready() {
      if (this.ready) {
        document.querySelector("#loading").style.opacity = "1";
        document.querySelector("#loading .content").style.opacity = "0";
      }
      if (!this.ready) $("#loading").addClass("active");
    }
  },

  computed: {
    dividerHeight() {
      return window.innerHeight < this.hero.height
        ? this.hero.height
        : window.innerHeight;
    }
  }
}).$mount("#app");
